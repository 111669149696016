import { useEffect, useState } from 'react';
import { useAtom } from "jotai";
import { currentTheme } from "../store";

const useThemeSwitcher = () => {
	const [theme, setTheme] = useState("dark");
	const [themeAtom, setThemeAtom] = useAtom(currentTheme);
	const activeTheme = theme === 'dark' ? 'light' : 'dark';
	useEffect(() => {
		const root = window.document.documentElement;

		root.classList.remove(activeTheme);
		root.classList.add(theme);
		localStorage.setItem('theme', theme);
		setThemeAtom(activeTheme);
	}, [theme, activeTheme]);

	return [activeTheme, setTheme];
};

export default useThemeSwitcher;
