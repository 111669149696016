import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AppFooter from './components/shared/AppFooter';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import UseScrollToTop from './hooks/useScrollToTop';
import PortfolioDetail from './pages/Services/PortfolioDetail';

const AboutUs = lazy(() => import('../src/pages/AboutUs/AboutUs'));
const Contact = lazy(() => import('../src/components/contact/ContactForm'));
const Home = lazy(() => import('./pages/Home'));
const Projects = lazy(() => import('./pages/Projects'));
const ProjectSingle = lazy(() => import('./pages/ProjectSingle.jsx'));
const Vision = lazy(() => import('./pages/AboutUs/Vision'));
const Mission = lazy(() => import('./pages/AboutUs/Mission'));
const Values = lazy(() => import('./pages/AboutUs/OurValues'));
const OurLeaders = lazy(() => import('./pages/AboutUs/OurLeaders'));
const Services = lazy(() => import('./pages/Services/Services'));
const OurServices = lazy(() => import('./pages/Services/OurServices'));
const Partners = lazy(() => import('./pages/Services/Partners'));
const Investor = lazy(() => import('./pages/Investor/Investor'));
const FinancialReports = lazy(() => import('./pages/Investor/FinancialReport'));
const Jobs = lazy(() => import('./pages/Careers/Jobs'));
const LifeatOgya = lazy(() => import('./pages/Careers/LifeatOgya'));
const Portfolio = lazy(() => import('./pages/Services/Portfolio'));
const SolutionDetail = lazy(() => import('./pages/Services/SolutionDetail'));
const PartnersDetail = lazy(() => import('./pages/Services/PartnersDetail'));


function App() {
	return (
		<AnimatePresence>
			<div className=" bg-secondary-light dark:bg-primary-dark transition duration-300">
				<Router>
					<ScrollToTop />
					<AppHeader />
					<Suspense fallback={""}>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="projects" element={<Projects />} />
							<Route
								path="projects/single-project"
								element={<ProjectSingle />}
							/>

							<Route path="about" element={<AboutUs />} />
							<Route path="about/ourleader" element={<AboutUs />} />
							{/* <Route path="about/vision" element={<AboutUs />} />
							<Route path="about/mission" element={<AboutUs />} />
							<Route path="about/ourvalues" element={<AboutUs />} /> */}
							<Route path="about/combine" element={<AboutUs />} />
							<Route path="service" element={<Services />} />
							<Route path="service/ourServices" element={<Services />} />
							<Route path="service/partners" element={<Services />} />
							<Route path="solutionDetail/:id" element={<SolutionDetail />} />
							<Route path="investor" element={<Investor />} />
							<Route path="financialReports" element={<FinancialReports />} />
							<Route path="jobs" element={<Jobs />} />
							<Route path="lifeatogya" element={<LifeatOgya />} />
							<Route path="contact" element={<Contact />} />
							<Route path="service/portfolio" element={<Portfolio />} />
							<Route path="portfolio" element={<Portfolio />} />
							<Route path="portfolioDetail/:id" element={<PortfolioDetail />} />
							<Route path="partnersDetail/:id" element={<PartnersDetail />} />
						</Routes>
					</Suspense>
					<AppFooter />
				</Router>
				<UseScrollToTop />
			</div>
		</AnimatePresence>
	);
}

export default App;
