function AppFooterCopyright() {
	return (
		<div className="font-general-regular flex justify-center items-center text-center">
			<div className="text-sm text-ternary-dark dark:text-ternary-light">
				&copy; 2023. PT Ogya Tekno Nusantara
			</div>
		</div>
	);
}

export default AppFooterCopyright;
