import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FiGithub,
  FiTwitter,
  FiLinkedin,
  FiMapPin,
  FiYoutube,
  FiChevronsRight,
  FiX,
  FiMail,
  FiPhone,
} from "react-icons/fi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsTelegram } from "react-icons/bs";
import AppFooterCopyright from "./AppFooterCopyright";
import headsetIcon from "../../images/headphones-icon.png";
import FormInput from "../reusable/FormInput";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

const socialLinks = [
  {
    id: 1,
    icon: <FiMapPin />,
    url: "https://www.google.com/maps/place/PT+Ogya+Tekno+Nusantara/@-6.224351,106.8392229,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f3e895805a87:0x7948b876f18d1b2e!8m2!3d-6.2243563!4d106.8418032!16s%2Fg%2F11qgy2c5pb?entry=ttu",
  },
  {
    id: 2,
    icon: <FiPhone />,
    url: "tel:021-21283096",
  },
  {
    id: 3,
    icon: <FiLinkedin />,
    url: "https://www.linkedin.com/company/ogya-tekno-nusantara/",
  },
  {
    id: 4,
    icon: <AiOutlineWhatsApp />,
    url: "https://wa.me/message/Q6C47WWIPMHQO1",
  },
  {
    id: 5,
    icon: <BsTelegram />,
    url: "https://t.me/OgyaTeknoNusantara",
  },

];

const AppFooter = () => {
  const [show, setShow] = useState(false);
  const { i18n, t } = useTranslation();
  const [disabledBtn, setDisabledBtn] = useState(true)

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    company_name: '',
    topic: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = '*Name is required';
    }
    if (!formData.phone) {
      errors.phone = '*Phone Number is required';
    }
    if (!formData.email) {
      errors.email = '*Email is required';
    }
    if (!formData.company_name) {
      errors.company_name = '*Company Name is required';
    }
    if (!formData.topic) {
      errors.topic = '*Topic Inquiry is required';
    }
    if (!formData.message) {
      errors.message = '*Message is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('ini data', formData)

    const isFormValid = validateForm();

    if (isFormValid) {
      try {
        const response = await axios.post('http://admin-web.ogya.co.id/api/contact-us', formData);
        console.log('Response from server:', response.data);

        setShowSuccessModal(true);

        setFormData({
          name: '',
          phone: '',
          email: '',
          company_name: '',
          topic: '',
          message: '',
        });
      } catch (error) {
        console.error('Error sending data:', error);
      }
    }
  };

  const onChange = (value) => {
    setDisabledBtn(false)
    console.log("Captcha value:", value)
  }

  return (
    <div className="container mx-auto">
      <div className="pt-20 sm:pt-30 pb-8 mt-5">
        {/* Footer social links */}
        <div className="flex justify-between mb-20">
          <div className="flex flex-col md:gap-4 md:flex-row">
            <div className="flex flex-col mt-2 md:mt-2">
              <Link to="/about"><p
                className="font-general-bold text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left"
              >
                {t("ABOUTUS")}
              </p></Link>
              <div className="flex flex-col">
                <Link to="/about/ourleader"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("leader")}
                </p></Link>
                <Link to="/about/combine"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("visi")}
                </p></Link>
                <Link to="/about/combine"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("misi")}
                </p></Link>
                <Link to="/about/combine"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("value")}
                </p></Link>
              </div>
            </div>
            <div className="flex flex-col mt-2 md:mt-2">
              <Link to="/service/ourServices">
                <p
                  className="font-general-bold text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left"
                >
                  {t("SERVICES")}
                </p>
              </Link>
              <div className="flex flex-col">
                <Link to="/service/ourServices"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("ourService")}
                </p></Link>
                <Link to="/service/partners"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("partners")}
                </p></Link>
                <Link to="/portfolio"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("portfolio")}
                </p></Link>

              </div>
            </div>
            {/* <div className="flex flex-col mt-2 md:mt-2">
              <p className="font-general-bold text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                Investor Relations
              </p>
              <div className="flex flex-col">
                <p className="text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  Financial Reports
                </p>
                <p className="text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  CSR
                </p>
              </div>
            </div> */}
            <div className="flex flex-col mt-2 md:mt-2">
              <Link to="/lifeatogya">
                <p
                  className="font-general-bold text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left"
                >
                  {t("CAREERS")}
                </p>
              </Link>
              <div className="flex flex-col">
                <Link to="/lifeatogya"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("Life")}
                </p></Link>
                <Link to="/jobs"><p className="flex flex-row text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-left">
                  {t("jobs")}
                </p></Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-2 md:mt-2" style={{ paddingTop: 80 }}>
            {/* <p className="text-sm text-primary-dark dark:text-primary-light mb-1 md:mb-2 text-right">
              {t("PrivacyPolicy")}
            </p>
            <p className="text-sm text-primary-dark dark:text-primary-light mb-4 text-right">
              {t("TermsofService")}
            </p> */}
            <ul className="flex gap-4">
              {socialLinks.map((link) => (
                <a
                  href={link.url}
                  target="__blank"
                  key={link.id}
                  className="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-1 duration-300"
                >
                  <i className="text-xl">{link.icon}</i>
                </a>
              ))}
            </ul>
          </div>
        </div>
        <AppFooterCopyright />
      </div>
      <a style={fixedButton} href>
        <div
          style={roundedFixedBtn}
          className="btn-contact"
          onClick={(e) => {
            e.stopPropagation();
            setShow(true);
          }}
        >
          <img src={headsetIcon} alt="contact us" width={36} height={36} />
          <span className="font-general-bold">Contact Us</span>
        </div>
      </a>
      {show ? (
        <div className="chat-popup" id="myForm">
          <form action="#" className="form-container" onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between">
              <span className="font-general-bold">{t("contact")}</span>
              <FiX
                className="text-xl cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(false);
                }}
                style={{
                  backgroundColor: "#cdcdcd",
                  borderRadius: "15px",
                  padding: 2,
                }}
              />
            </div>
            <Form className="mt-2">
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3" controlId="formGroup"
                  >
                    <Form.Label
                      className="font-general-bold"
                    >
                      {t("Name")}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.name}
                      name="name"
                      onChange={handleInputChange}
                    />
                    {formErrors.name && (
                      <div className="text-danger">{formErrors.name}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formGroup"
                  >
                    <Form.Label
                      className="font-general-bold"
                    >
                      {t("PhoneNumber")}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxlength="13"
                      value={formData.phone}
                      name="phone"
                      onChange={handleInputChange}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {formErrors.phone && (
                      <div className="text-danger">{formErrors.phone}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formGroup"
                  >
                    <Form.Label
                      className="font-general-bold"
                    >
                      {t("Email")}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.email}
                      name="email"
                      onChange={handleInputChange}
                    />
                    {formErrors.email && (
                      <div className="text-danger">{formErrors.email}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formGroup">
                    <Form.Label
                      className="font-general-bold"
                    >
                      {t("CompanyName")}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="company_name"
                      value={formData.company_name}
                      onChange={handleInputChange}
                    />
                    {formErrors.company_name && (
                      <div className="text-danger">{formErrors.company_name}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="mb-3"
              >
                <Form.Label
                  className="font-general-bold"
                >
                  {t("TopicInquiry")}*
                </Form.Label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="topic"
                  value={formData.topic}
                  onChange={handleInputChange}
                >
                  <option selected>{t("Whatdo")}</option>
                  <option value="1">{t("ApplicationDevelopment")}</option>
                  <option value="2">{t("DataSecurity")}</option>
                  <option value="3">{t("EnterpriseIntegration")}</option>
                  <option value="4">{t("Maintenance")}</option>
                  <option value="5">
                    {t("Implementation")}{" "}
                  </option>
                  <option value="6">{t("ManagedService")}</option>
                </select>
                {formErrors.topic && (
                  <div className="text-danger">{formErrors.topic}</div>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="formGroup">
                <Form.Label
                  className="font-general-bold"
                >
                  {t("Messages")}*
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
                {formErrors.message && (
                  <div className="text-danger">{formErrors.message}</div>
                )}
              </Form.Group>
            </Form>
            <ReCAPTCHA
              sitekey="6Ldb4zkoAAAAACkmBpGcA0DzZMyF7JRsWIV2Kfo-"
              onChange={onChange}
              style={{ marginBottom: 10 }}
            />
            <button type="submit" className="btn" disabled={disabledBtn}
              style={{
                marginTop: 20, background: disabledBtn ? '#D0D0D0' : '#0b5ed7'
              }}>
              {t("send")} <FiMail style={{ marginTop: 3.5, marginLeft: 5 }} />
            </button>
          </form>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AppFooter;

const fixedButton = {
  position: "fixed",
  bottom: "0px",
  left: "0px",
  padding: "20px",
};
const roundedFixedBtn = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  color: "white",
  textAlign: "center",
  cursor: "pointer",
  padding: "4px",
  paddingLeft: "8px",
  borderRadius: "8px",
  borderTop: "1px solid #cdcdcd",
  borderLeft: "1px solid #cdcdcd",
  borderRight: "1px solid #cdcdcd",
  borderBottom: "4px solid #154ace",
};
