import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import {
  FiMenu,
  FiMoon,
  FiSun,
  FiX,
  FiMinus,
  FiUsers,
  FiGrid,
  FiHeart,
  FiMail,
} from "react-icons/fi";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import HireMeModal from "../HireMeModal";
import logoLight from "../../images/WHITE-04 3.png";
import logoDark from "../../images/OGYA LOGO-01 1.png";
import { motion } from "framer-motion";
import { NavDropdown } from "react-bootstrap";
// import Button from "../reusable/Button";

import flagId from "../../images/id.png";
import flagEn from "../../images/en.png";

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

// create a button that calls a context function to set a new open state when clicked
const Button = () => {
  const ctx = useContext(MyContext);

  return (
    <button onClick={ctx.toggleMenu}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
      >
        {ctx.isMenuOpen ? (
          // <FiX className="text-3xl" />
          <></>
        ) : (
          <FiMenu className="text-3xl" />
        )}
      </svg>
    </button>
  );
};

// create a navigation component that wraps the burger menu
const SideNav = ({ activeTheme, setTheme, lang, onChangeLang }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location, 'lc');
  const ctx = useContext(MyContext);

  return (
    <div className={ctx.isMenuOpen ? "block" : "hidden"}>
      <Menu
        customBurgerIcon={false}
        isOpen={ctx.isMenuOpen}
        onStateChange={(state) => ctx.stateChangeHandler(state)}
      >
        <div>
          <div className="flex justify-between p-4">
            <Link to="/">
              <img src={logoDark} className="w-24" alt="Dark Logo" />
            </Link>
            <FiX
              className="text-xl"
              style={{
                marginTop: 5,
                backgroundColor: "#cdcdcd",
                borderRadius: 20,
                padding: 3,
              }}
            />
          </div>
          <Navigation
            // you can use your own router's api to get pathname
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              if (
                itemId !== "/menu1" &&
                itemId !== "/menu2" &&
                itemId !== "/menu3"
              ) {
                navigate(itemId);
                ctx.toggleMenu(false);
              }
            }}
            items={[
              {
                title: "About Us",
                itemId: "/menu1",
                elemBefore: () => <FiUsers />,
                subNav: [
                  {
                    title: "About OGYA",
                    itemId: "/about",
                    // Requires v1.9.1+ (https://github.com/abhijithvijayan/react-minimal-side-navigation/issues/13)
                    elemBefore: () => <FiMinus />,
                  },
                  {
                    title: "Our Leader",
                    itemId: "/about/ourleader",
                    elemBefore: () => <FiMinus />,
                  },
                  {
                    title: "Vision",
                    itemId: "/about/vision",
                    // Requires v1.9.1+ (https://github.com/abhijithvijayan/react-minimal-side-navigation/issues/13)
                    elemBefore: () => <FiMinus />,
                  },
                  {
                    title: "Mission",
                    itemId: "/about/mission",
                    elemBefore: () => <FiMinus />,
                  },
                  {
                    title: "Values",
                    itemId: "/about/ourvalues",
                    elemBefore: () => <FiMinus />,
                  },
                ],
              },
              {
                title: "Services",
                itemId: "/menu2",
                elemBefore: () => <FiGrid />,
                subNav: [
                  // {
                  //   title: "Services",
                  //   itemId: "/service",
                  //   elemBefore: () => <FiMinus />,
                  // },
                  {
                    title: "Our Services",
                    itemId: "/service/ourServices",
                    elemBefore: () => <FiMinus />,
                  },
                  {
                    title: "Technology Partners",
                    itemId: "/service/partners",
                    elemBefore: () => <FiMinus />,
                  },
                  {
                    title: "Portfolio",
                    itemId: "/portfolio",
                    elemBefore: () => <FiMinus />,
                  },
                ],
              },
              {
                title: "Careers",
                itemId: "/menu3",
                elemBefore: () => <FiHeart />,
                subNav: [
                  {
                    title: "Life at Ogya",
                    itemId: "/lifeatogya",
                    elemBefore: () => <FiMinus />,
                  },
                  {
                    title: "Jobs",
                    itemId: "/jobs",
                    elemBefore: () => <FiMinus />,
                  },
                ],
              },
              {
                title: "Contact Us",
                itemId: "/contact",
                elemBefore: () => <FiMail />,
                // Optional
                // elemBefore: () => <FiX />
              },
            ]}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "col",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <div
            onClick={() => onChangeLang()}
            className="bg-primary-light dark:bg-ternary-dark shadow-sm cursor-pointer"
            style={{
              width: 42,
              padding: "1px",
              borderRadius: "42px",
              marginRight: 15,
              height: 44,
            }}
          >
            <img
              src={lang === "id" ? flagId : flagEn}
              alt=""
              style={{
                padding: "1px",
                borderRadius: "30px",
                width: "42px",
                height: "42px",
              }}
            />
          </div>

          <div
            onClick={() => setTheme(activeTheme)}
            aria-label="Theme Switcher"
            className="sm:hidden ml-0 bg-primary-light dark:bg-ternary-dark p-3 shadow-sm cursor-pointer"
            style={{
              width: 45,
              height: 45,
              borderRadius: 30,
            }}
          >
            {activeTheme === "dark" ? (
              <FiMoon
                className="text-ternary-dark hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl"
                style={{ marginTop: -3, marginLeft: -3 }}
              />
            ) : (
              <FiSun
                className="text-gray-200 hover:text-gray-50 text-xl"
                style={{ marginTop: -3, marginLeft: -3 }}
              />
            )}
          </div>
        </div>
      </Menu>
    </div>
  );
};

const AppHeader = () => {
  const location = useLocation();
  const ctx = useContext(MyContext);
  const { i18n, t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [lang, setLang] = useState("en");
  const [activeTheme, setTheme] = useThemeSwitcher();
  const [dropdown, setDropdown] = useState(false);
  const [dropdownService, setDropdownServices] = useState(false);
  const [dropdownInvestor, setDropdownInvestor] = useState(false);
  const [dropdownCareers, setDropdownCareers] = useState(false);
  const [navbar, setNavbar] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  
  const [hover, setHover] = useState('');

	const handleMouseEnter = () => {
		setHover(true);
	};

	const handleMouseLeave = () => {
		setHover(false);
	};

  const stopClickPropagation = (event) => {
    event.stopPropagation();
    setShow(false);
    setShow2(false);
    setShow3(false);
  };

  function showHireMeModal() {
    if (!showModal) {
      document
        .getElementsByTagName("html")[0]
        .classList.add("overflow-y-hidden");
      setShowModal(true);
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("overflow-y-hidden");
      setShowModal(false);
    }
  }
  useEffect(() => {
    localStorage.setItem('lang', JSON.stringify(lang));
  }, [lang]);

  const onChangeLang = () => {
    if (lang === "id") {
      setLang("en");
      i18n.changeLanguage("en");
    } else {
      setLang("id");
      i18n.changeLanguage("id");
    }
  };

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      id="nav"
      className="sm:container sm:mx-auto"
      style={{position: "relative", zIndex: 9}}
    >
      {/* Header links small screen */}
      <MyProvider>
        <div>
          <SideNav
            activeTheme={activeTheme}
            setTheme={setTheme}
            lang={lang}
            onChangeLang={onChangeLang}
          />
        </div>

        <div className="z-10 max-w-screen-lg xl:max-w-screen-xl block py-3 sm:flex sm:justify-between sm:items-center">
          {/* Header menu links and small screen hamburger menu */}
          <div className="flex justify-between items-center px-4 sm:px-0">
            <div>
              <Link to="/">
                {activeTheme === "dark" ? (
                  <img src={logoDark} style={{ width: "150px" }} alt="Dark Logo" />
                ) : (
                  <img src={logoLight} style={{ width: "150px" }} alt="Light Logo" />
                )}
              </Link>
            </div>

            {/* Small screen hamburger menu */}
            <div className="sm:hidden">
              <Button />
            </div>
          </div>

          {/* Header links large screen */}
          <div className="font-general-medium hidden m-0 sm:ml-4 sm:flex p-4 sm:p-0 justify-right sm:shadow-none">
            <NavDropdown
              title={t("about")}
              className={location.pathname === '/lifeatogya' ? "text-lg text-white sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2" : location.pathname.split("/")[1] === 'portfolioDetail' ? "text-lg text-white sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2" : "text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2"}
              show={show}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <NavDropdown.Item
                as={Link}
                to={"/about"}
                onClick={stopClickPropagation}
              >
                {/* About OGYA */}
                {t("about")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={"/about/ourleader"}
                onClick={stopClickPropagation}
              >
                {/* Our Leader */}
                {t("leader")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                // to={"/about/vision"}
                to={"/about/combine"}
                onClick={stopClickPropagation}
              >
                {/* Vision */}
                {t("visi")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                // to={"/about/mission"}
                to={"/about/combine"}
                onClick={stopClickPropagation}
              >
                {/* Mission */}
                {t("misi")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                // to={"/about/ourvalues"}
                to={"/about/combine"}
                onClick={stopClickPropagation}
              >
                {/* Values */}
                {t("value")}
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={t("service")}
              className={location.pathname === '/lifeatogya' ? "text-lg text-white sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2" : location.pathname.split("/")[1] === 'portfolioDetail' ? "text-lg text-white sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2" : "text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2"}
              show={show2}
              onMouseEnter={() => setShow2(true)}
              onMouseLeave={() => setShow2(false)}
            >
              {/* <NavDropdown.Item
                as={Link}
                to={"/service"}
                onClick={stopClickPropagation}
              >
                Services
              </NavDropdown.Item>
              <NavDropdown.Divider /> */}
              <NavDropdown.Item
                as={Link}
                to={"/service/ourServices"}
                onClick={stopClickPropagation}
              >
                {/* Our Services */}
                {t("ourService")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={"/service/partners"}
                onClick={stopClickPropagation}
              >
                {/* Technology Partners */}
                {t("partners")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={"/portfolio"}
                onClick={stopClickPropagation}
              >
                {/* Portfolio */}
                {t("portfolio")}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={t("careers")}
              className={location.pathname === '/lifeatogya' ? "text-lg text-white sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2" : location.pathname.split("/")[1] === 'portfolioDetail' ? "text-lg text-white sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2" : "text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 pt-3 sm:pt-2"}
              show={show3}
              onMouseEnter={() => setShow3(true)}
              onMouseLeave={() => setShow3(false)}
            >
              <NavDropdown.Item
                as={Link}
                to={"/lifeatogya"}
                onClick={stopClickPropagation}
              >
                {/* Life at Ogya */}
                {t("Life")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to={"/jobs"}
                onClick={stopClickPropagation}
              >
                {/* Jobs */}
                {t("jobs")}
              </NavDropdown.Item>
            </NavDropdown>

            <Link
              to="/contact"
              // className="block bg-blue rounded-xl text-left text-lg text-white dark:text-ternary-light hover:text-white dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2 sm:px-4"
              className={location.pathname === '/lifeatogya' ? "block text-left text-lg text-white  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light" : "block text-left text-lg text-primary-dark dark:text-ternary-light hover:text-secondary-dark dark:hover:text-secondary-light  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"}
              aria-label="Contact"
            >
              <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ ...button, backgroundColor: hover ? '#068FFF' : '#0b5ed7' }}>
                <span className="block rounded-xl text-center text-lg text-white dark:text-ternary-light hover:text-white dark:hover:text-secondary-light sm:mx-4 sm:py-2 sm:px-4"
                >
                  {t("contact")}
                </span>
              </button>
            </Link>
            {/* <a
              href="/contact"
              className="font-general-medium flex justify-right items-center mt-2 mb-6 sm:mb-0 text-lg justify-right"
              aria-label="Download Resume"
            >
              <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ ...button, backgroundColor: hover ? '#068FFF' : '#0b5ed7' }}>
                <span className="block rounded-xl text-center text-lg text-white dark:text-ternary-light hover:text-white dark:hover:text-secondary-light sm:mx-4 sm:py-2 sm:px-4"
                >
                  {t("contact")}
                </span>
              </button>
            </a> */}
          </div>

          {/* Header right section buttons */}
          <div className="hidden sm:flex justify-between items-center flex-col md:flex-row">
            {/* Theme switcher large screen */}
            <div
              onClick={() => onChangeLang()}
              className="bg-primary-light dark:bg-ternary-dark shadow-sm rounded-sm cursor-pointer"
              style={{ padding: "1px", borderRadius: "30px" }}
            >
              <img
                src={lang === "id" ? flagId : flagEn}
                alt=""
                style={{
                  padding: "1px",
                  borderRadius: "30px",
                  width: "34px",
                  height: "34px",
                }}
              />
            </div>
            <div
              onClick={() => setTheme(activeTheme)}
              aria-label="Theme Switcher"
              className="bg-primary-light dark:bg-ternary-dark p-2 shadow-sm cursor-pointer"
              style={{
                width: 37,
                height: 37,
                borderRadius: 30,
                marginLeft: "15px"
              }}
            >
              {activeTheme === "dark" ? (
                <FiMoon
                  className="text-ternary-dark hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl"
                  style={{ marginTop: 1, marginLeft: 0.6 }}
                />
              ) : (
                <FiSun
                  className="text-gray-200 hover:text-gray-50 text-xl"
                  style={{ marginTop: 1, marginLeft: 0.6 }}
                />
              )}
            </div>
          </div>
        </div>
        {/* Hire me modal */}
        <div>
          {showModal ? (
            <HireMeModal
              onClose={showHireMeModal}
              onRequest={showHireMeModal}
            />
          ) : null}
          {showModal ? showHireMeModal : null}
        </div>
      </MyProvider>
    </motion.nav>
  );
};

export default AppHeader;

const button = {
	backgroundColor: "#0b5ed7",
	borderRadius: 10,
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
}