// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

import { createRoot } from "react-dom/client";
import "./i18n";
import { Provider } from "jotai";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider>
    <App />
  </Provider>
);

// reportWebVitals();
