import { Parallax } from "react-parallax";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Carousel, CarouselItem } from 'react-bootstrap';
import { Container, Row, Col } from "react-bootstrap";
import quote from "../../images/Vector.png";
import img from '../../images/img.png'
import underlined from '../../images/underlined.png';
import axios from 'axios';
import { Link } from "react-router-dom";

const baseURL = "https://admin-web.ogya.co.id";

const PortofolioDetail = () => {
    const { i18n, t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    const [project, setProject] = useState(null);
    const [testimoni, setTestimoni] = useState(null);
    const [tech, setTech] = useState(null);
    const [feature, setFeature] = useState(null);
    const [fotoDetail, setFotoDetail] = useState(null);
    const [outsource, setOutsource] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);

    const params = useParams();

    useEffect(() => {
        axios.get(baseURL + `/api/project/` + params.id).then((response) => {
            setProject(response.data.data);
            setTestimoni(response.data.testimoni);
            setBackgroundImage(response.data.data);
            setTech(response.data.data);
            setFeature(response.data.feature);
            setFotoDetail(response.data.foto_detail);
            console.log('data', response.data.feature)
        });
    }, [])

    useEffect(() => {
        axios.get(baseURL + `/api/outsource`).then((response) => {
            setOutsource(response.data.data);
            console.log('outsource', response.data.data)
        });
    }, [])

    return (
        <div
            style={{ overflowX: "hidden", overflowY: "auto", marginTop: -160 }}>
            {
                project ? project.map(item =>
                    <Parallax
                        bgImage={item.background_detail}
                        bgImageStyle={{ objectFit: "cover" }}
                        strength={100}
                    >
                        <div style={{ height: "100vh" }}>
                            <Row>
                                <Col xs={12}>
                                    <div style={insideStyles}>
                                        {item.name_project}
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div style={insideStyles2} className="justify-content-center">
                                        <Link to={'/solutionDetail/' + item.id_solution}>
                                            <button
                                                style={hovered ? buttonHovered : button}
                                                onMouseEnter={() => setHovered(true)}
                                                onMouseLeave={() => setHovered(false)}
                                            >
                                                <span className="font-general-bold text-xl text-center">
                                                    <div dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? item.solution_english : item.solution_indo }}></div>
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Parallax>
                ) : <></>
            }

            <div style={{ marginTop: 100, marginBottom: 100 }} className="container mx-auto">
                <div className="flex flex-col w-full text-center">
                    {
                        project ? project.map(item =>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    ease: "easeInOut",
                                    duration: 0.9,
                                    delay: 0.1,
                                }}
                                className="font-general-bold text-left text-2xl  text-ternary-dark dark:text-primary-light"
                            >
                                <Row style={{ marginBottom: 20 }} className="align-items-start">
                                    <Col xs={6} md={2} style={{ marginRight: -160 }}>
                                        <img alt="img" src={img} />
                                    </Col>
                                    <Col xs={6} md={10}>
                                        <p className="text-ternary-dark dark:text-primary-light">
                                            {item.name_project === "Outsourcing" ? "Our Client" : "What Is Happening ?"}
                                        </p>
                                    </Col>
                                </Row>

                                {item.name_project === "Outsourcing" ?
                                    <Row>
                                        <Col xs={12} md={4}>

                                            <Carousel controls={false} indicators={false}>
                                                {
                                                    outsource ? outsource.map(item =>
                                                        <CarouselItem>
                                                            <Row>
                                                                <Col xs={12} md={12} style={{ marginRight: -450 }}>
                                                                    <img alt="logo" src={item.image} style={{ borderRadius: "50%" }} />
                                                                </Col>
                                                            </Row>
                                                        </CarouselItem>
                                                    ) : <></>
                                                }
                                            </Carousel>
                                        </Col>
                                        <Col xs={12} md={8} style={{ fontSize: 20, marginTop: 60 }}>
                                            <p className="text-ternary-dark dark:text-primary-light">
                                                <div dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? item.desc_english : item.desc_indo }}></div>
                                            </p>
                                        </Col>
                                    </Row>

                                    : <Row>
                                        <Col xs={6} md={4}>
                                            <img alt="logo" src={item.logo_client} style={{ borderRadius: "50%" }} />
                                        </Col>
                                        <Col xs={12} md={8} >
                                            <p style={{ fontSize: 20 }} className="text-ternary-dark dark:text-primary-light">
                                                <div dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? item.desc_english : item.desc_indo }}></div>
                                            </p>
                                        </Col>
                                    </Row>
                                }


                            </motion.div>
                        ) : <></>
                    }
                </div>
            </div>
            {
                project ? project.map(item =>
                    <div>
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                backgroundSize: "cover",
                                color: "black",
                                fontSize: "30px",
                                textAlign: "center",
                                position: "relative",
                            }}
                        >
                            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ color: 'black', fontFamily: "Roboto-Bold", marginTop: 50, marginLeft: 30 }}>
                                    {t("WhatWeDo")}
                                </p>
                                <img alt="img" src={underlined} style={{ width: 230, height: 5, marginLeft: 30 }} />
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Col xs={{ span: 11, offset: 1 }} md={{ span: 9, offset: 3 }}>
                                    <Carousel controls={false} indicators={false} style={{ alignItems: 'center', justifyContent: 'center', color: "black" }}>
                                        {
                                            fotoDetail ? fotoDetail.map(item =>
                                                <Carousel.Item>
                                                    <img src={item.image} style={{ width: "40%", marginTop: 50, marginLeft: 150 }} />
                                                </Carousel.Item>
                                            ) : <></>
                                        }
                                    </Carousel>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 100, marginTop: 100 }}>
                                <Col md={{ span: 6, offset: 3 }}>
                                    <p style={{ color: 'black', fontSize: 25 }}>
                                        <div dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? item.uraian_english : item.uraian_indo }}></div>
                                    </p>
                                </Col>

                            </Row>
                            <Row style={{ marginTop: 50 }}>
                                <p style={{ color: 'blue', fontFamily: "Roboto-Bold" }}>
                                    {item.subtitle}
                                </p>
                            </Row>
                            <Row style={{ marginTop: 50, justifyContent: "center" }}>
                                {
                                    feature ? feature.map(item =>
                                        <Col xs={{ span: 10, offset: 2 }} md={{ span: 10, offset: 2 }}>
                                            <img src={item.image} style={{ width: "80%", marginBottom: 50 }} />
                                            <p style={{ color: "black", fontSize: 14 }}>
                                                <div dangerouslySetInnerHTML={{ __html: i18n.language === "en" ? item.desc_english : item.desc_indo }} ></div>
                                            </p>
                                        </Col>
                                    ) : <></>
                                }
                            </Row>
                            {item.name_project === "Outsourcing" ? <></> :
                                <Container>
                                    <Row style={{ marginTop: 100 }}>
                                        <Col>
                                            <p style={{ color: 'blue', fontFamily: "Roboto-Bold" }}>
                                                {t("TechnicalSolution")}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 50, justifyContent: "center" }}>
                                        {
                                            item.tech.map(item2 =>
                                                <Col xs={6} sm={6} md={2}>
                                                    <img src={item2.image_stack} className="img-fluid" alt="Logo 1" style={{ width: '80px', margin: '0 auto' }} />
                                                    <p style={{ color: "black", fontSize: 14, marginBottom: 100 }}>
                                                        {item2.name_stack}
                                                    </p>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Container>
                            }
                        </div>
                    </div>
                ) : <></>
            }
            <div className="container mx-auto">
                <motion.section
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeInOut", duration: 0.9, delay: 0.2 }}
                    className="flex flex-col sm:justify-between items-center sm:flex-row my-7"
                >
                    <div className="flex flex-col w-full text-center">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                ease: "easeInOut",
                                duration: 0.9,
                                delay: 0.1,
                            }}
                            className="font-general-bold text-center text-3xl text-center text-ternary-dark dark:text-primary-light"
                        >
                            <p className="text-ternary-dark dark:text-primary-light">
                                {t("Testimony")}
                            </p>
                            <Container>
                                <Row>
                                    {
                                        testimoni ? testimoni.map(item =>
                                            <Row>
                                                <Col sm={12}>
                                                    <Row>
                                                        <div className="sm:mt-20">
                                                            <img src={quote} width={50} />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div>
                                                            <div
                                                                style={{
                                                                    fontSize: "20px",
                                                                    textAlign: "left",
                                                                    position: "relative"
                                                                }}
                                                            >
                                                                <p className="flex justify-center mt-3 text-ternary-dark dark:text-primary-light">
                                                                    {i18n.language === "en" ? item.testimoni_english : item.testimoni_indo}
                                                                </p>{" "}
                                                                <br />
                                                                <p className="justify-center mt-0 text-ternary-dark dark:text-primary-light">
                                                                    <strong>{item.name_reviewer}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ) : <></>
                                    }
                                </Row>
                            </Container>
                        </motion.div>
                    </div>
                </motion.section>
            </div>
        </div>
    );
};

export default PortofolioDetail;

const insideStyles = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: "white",
    fontSize: "65px",
    textAlign: "center",
    fontFamily: "Roboto-Bold",
    lineHeight: "normal",
};

const insideStyles2 = {
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: "white",
};

const button = {
    background: "#ffffff",
    border: "none",
    color: "rgb(15, 45, 239)",
    padding: "10px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "flex",
    margin: "10px 10px",
    cursor: "pointer",
    borderRadius: "16px",
    flexWrap: "wrap",
    left: "30px",
    transition: "background-color 0.3s"
};

const buttonHovered = {
    ...button,
    background: "rgb(15, 45, 239)",
    color: "#ffffff"
};