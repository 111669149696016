import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  //   .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    // lng: getCurrentLang(),
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          title: "Multi-language app",
          about: "About Us",
          service: "Services",
          investor: "Investor Relations",
          careers: "Careers",
          contact: "Contact Us",
          leader: "Our Leader",
          visi: "Vision",
          misi: "Mission",
          value: "Values",
          ourService: "Our Services",
          partners: "Technology Partners",
          portfolio: "Portfolio",
          lifeatogya: "Life at Ogya",
          jobs: "Jobs",
          digitalize: "Digitalize with us and",
          reach: "reach higher productivity.",
          learn: "Learn more",
          businesses: "Businesses had higher productivity after we helped them go digital.",
          through: "Through digitalization, we can help you improve productivity through better efficiency. With years of experience, our team has the confidence to help your business thrive.",
          Getintouch: "Get in touch",
          satisfied: "Satisfied collaborators",
          hear: "Hear their stories to find out more.",
          we: "We have a dream", to: "to help you", transform: "transform.",
          our: "Our Journey",
          founded: "Founded in 2017, Ogya started with the dream of helping other businesses improve.", with: "With the knowledge and experience to improve business processes through digitalization,", ogyan: "Ogya managed to help organizations thrive.",
          acting: "Acting as a catalyst, Ogya has helped numerous businesses grow until now.", weare: "We are continuously working towards our dream, now more specifically to help", those: "those in the financial industries.",
          webelieve: "We believe through digitalization, we can revolutionize the industry.", hence: "Hence making impactful changes for the economy.",
          meet: "Meet Our",
          leaders: "Leaders",
          meet1: "Meet our leaders.",
          notonly: "Not only due to our values, but it also takes leadership to move our ship forward.",
          Tobe: "To be a trusted and reliable", ITSolution: "IT Solution company in Indonesia",
          enlighten: "Enlighten customer satisfaction with understanding", ofwhat: "of what their needs and challenges,", andfurther: "and further bringing up ideas to life.",
          collaboration: "Collaboration",
          excellence: "Excellence",
          customerCentric: "Customer Centric",
          wework: "We work together toward the common goal.",
          passionate: "We are passionate about our people, our product,", and: "and our service quality excellence.",
          wevalue: "We value all interactions with our customers, and", bypartnering: "by partnering with them to understand and analyze", theirneeds: "their needs, we build solutions that they love.",
          custommade: "Custom-made", solutions: "solutions,", just: "just for you.",
          get: "Get a quote",
          findout: "Find out about our Solutions",
          TechnologyPartners: "Find out about our Technology Partners",
          ABOUTUS: "ABOUT US",
          SERVICES: "SERVICES",
          CAREERS: "CAREERS",
          PrivacyPolicy: "Privacy Policy",
          TermsofService: "Terms of Service",
          Life: "Life at Ogya",
          Explore: "Explore Careers with Us",
          AtOgya: "At Ogya, we deeply believe in Collaboration. Our people and our team is our biggest strength. We believe when spectacular people get together, we can synergize and create something bigger than ourselves.",
          Aswebelieve: "As we believe they are spectacular, we do not stop to improve to be excellent. This is why we developed programs to help our people to continue growing and be better than when they first came.",
          OurProgram: "Our Program",
          Training: "Training & Development",
          Webelieveourpeople: "We believe our people are diamonds that will shine bright if honed well. We want to see them become a better person than when they came in. We want to be a part of their journey.",
          TeamBuilding: "Team Building",
          Eachyear: "Each year, our team gets together to regroup & bond. The program was developed to strengthen our team and enhance our Collaboration.",
          CareerPlan: "Career Plan & Leadership Training",
          Webelievethateveryoneisaleader: "We believe that everyone is a leader. That is why career plan is important to us. We want to ensure that you will be a part of our future since Day One.",
          Activities: "Activities",
          WorkingatOgya: "Working at Ogya has been one of my greatest joy. Since Day One, I have been given the opportunity to explore, learn, and shine on my work. This place now feels more and more like home. Love it here!",
          JobOpportunities: "Job Opportunities",
          JobVacancy: "Job Vacancy",
          Functions: "Functions",
          YourQuestions: "Contact Us and Get Your Questions Answered!",
          Whyhesitate: "Why hesitate when the solution is just a form away?",
          Werehere: "We're here to provide you with the answers you need. Our team of tech experts is eagerly waiting to assist you on your IT journey. Whether it's about optimizing your network, enhancing your cybersecurity, or maximizing your digital infrastructure, we've got you covered.",
          Fillout: "Fill out our convenient contact form to get in touch with us. We understand that navigating the world of technology can be complex, and that's why we're committed to simplifying it for you. No query is too big or too small - we're dedicated to addressing all your concerns.",
          Name: "Full Name",
          PhoneNumber: "Phone Number",
          Email: "Email",
          CompanyName: "Company Name",
          TopicInquiry: "Topic Inquiry",
          NoticePeriod: "Notice Period",
          Whatdo: "What do you want to know more?",
          ApplicationDevelopment: "Application Development",
          DataSecurity: "Data and Application Security",
          EnterpriseIntegration: "Enterprise Integration",
          Maintenance: "Maintenance and Local Support",
          Implementation: "Implementation and Product Delivery",
          ManagedService: "Managed Service",
          OurGuidingCompass: "Our Guiding Compass",
          OurDevelopmentJourney: "Our Development Journey",
          SoftwareDevelopment: "Our Works on ",
          more: "More",
          OurTechnologyExpertise: "Our Technology Expertise",
          ApplyNow: "Apply Now",
          PersonalDetails: "Personal Details",
          UploadCV: "Upload CV",
          CoverLetter: "Cover Letter",
          Submit: "Submit",
          Requiredfield: "Required field",
          Department: "Department",
          Location: "Location",
          DatePosted: "Date Posted",
          OurWork: "Our Work",
          Interested: "Interested in creating something similarly exciting? Let's talk!",
          Messages: "Message",
          send: "Send",
          OurWorkswith: "Our Works with",
          TalkwithourEngineer: "Talk with our Engineer",
          Location: "Location",
          lantai: "3rd Floor, Unit A",
          PTOgyaTeknoNusantarastands: "PT Ogya Tekno Nusantara stands as a pioneering Information and Communication Technology (ICT) enterprise in Indonesia, dedicated to harmonizing business, human, and technological elements seamlessly.",
          AtOGYAparamount: "At OGYA, paramount importance is accorded to ensuring customer satisfaction through our exceptional IT business solutions, establishing OGYA as a trusted and reliable IT solution company in Indonesia.",
          WhatWeDo: " What We Do ?",
          Testimony: "Testimony",
          TechnicalSolution: "Technical Solution",

        },
      },
      id: {
        translation: {
          title: "Aplicación en varios idiomas",
          about: "Tentang Kami",
          service: "Layanan",
          investor: "Hubungan Investor",
          careers: "Karir",
          contact: "Hubungi Kami",
          leader: "Pemimpin Kami",
          visi: "Visi",
          misi: "Misi",
          value: "Nilai-nilai",
          ourService: "Layanan Kami",
          partners: "Partner Teknologi",
          portfolio: "Portofolio",
          lifeatogya: "Life at Ogya",
          jobs: "Pekerjaan",
          digitalize: "Digitalisasi bersama kami dan",
          reach: "capai produktivitas yang lebih tinggi.",
          learn: "Pelajari lebih lanjut",
          businesses: "Setelah kami membantu mereka bertransformasi digital, bisnis-bisnis mengalami peningkatan produktivitas yang signifikan.",
          through: "Melalui digitalisasi, kami dapat membantu meningkatkan produktivitas Anda melalui efisiensi yang lebih baik. Dengan pengalaman bertahun-tahun, Kami dapat membantu bisnis Anda berkembang.",
          Getintouch: "Hubungi kami",
          satisfied: "Kolaborator Kami",
          hear: "Dengarkan kisah mereka untuk mengetahui lebih lanjut.",
          we: "Kami memiliki mimpi", to: "untuk membantu Anda", transform: "bertransformasi.",
          our: "Perjalanan Kami",
          founded: "Berdiri pada tahun 2017, Ogya dimulai dengan mimpi untuk membantu bisnis lain berkembang.", with: "Dengan pengetahuan dan pengalaman dalam meningkatkan proses bisnis melalui digitalisasi,", ogyan: "Ogya berhasil membantu organisasi-organisasi berkembang dan maju.",
          acting: "Sebagai katalis, Ogya telah membantu banyak bisnis tumbuh hingga saat ini.", weare: "Kami terus bekerja menuju impian kami dan kini lebih berfokus untuk membantu", those: "industri keuangan.",
          webelieve: "Kami percaya melalui digitalisasi, kami dapat merevolusi industri ini.", hence: "Sehingga menciptakan perubahan yang berdampak bagi ekonomi.",
          meet: "Temui para,",
          leaders: "Pemimpin",
          meet1: "Temui para pemimpin kami.",
          notonly: "Tidak hanya karena nilai-nilai kami, tetapi juga dibutuhkan kepemimpinan untuk mendorong kapal kami maju.",
          Tobe: "Menjadi perusahaan Solusi IT", ITSolution: "yang terpercaya dan handal di Indonesia.",
          enlighten: "Meningkatkan kepuasan pelanggan", ofwhat: "dengan memahami kebutuhan dan tantangan mereka,", andfurther: "dan selanjutnya mewujudkan ide-ide mereka.",
          collaboration: "Kolaborasi",
          excellence: "Keunggulan",
          customerCentric: "Berorientasi pada pelanggan",
          wework: "Kami bekerja sama untuk mencapai tujuan bersama.",
          passionate: "Kami sangat antusias dengan karyawan kami, produk kami,", and: "dan keunggulan kualitas layanan kami.",
          wevalue: "Kami menghargai semua interaksi dengan pelanggan kami, dan", bypartnering: "dengan menjalin kemitraan dengan mereka untuk memahami dan menganalisis kebutuhan mereka,", theirneeds: "kami membangun solusi yang mereka sukai.",
          custommade: "Solusi", solutions: "yang disesuaikan,", just: "khusus untuk Anda.",
          get: "Dapatkan penawaran",
          findout: "Cari tahu tentang Solusi kami",
          TechnologyPartners: "Temukan lebih lanjut tentang Mitra Teknologi kami",
          ABOUTUS: "Tentang Kami",
          SERVICES: "Layanan",
          CAREERS: "Karir",
          PrivacyPolicy: "Kebijakan Privasi",
          TermsofService: "Syarat dan Ketentuan Layanan",
          Life: "Kehidupan di Ogya",
          Explore: "Jelajahi Karir bersama Kami",
          AtOgya: "Di Ogya, kami sangat percaya dalam Kolaborasi. Orang-orang kami dan tim kami adalah kekuatan terbesar kami. Kami percaya bahwa ketika orang-orang luar biasa berkumpul, kita dapat berkolaborasi dan menciptakan sesuatu yang lebih besar daripada diri kita sendiri.",
          Aswebelieve: "Karena kami percaya bahwa mereka luar biasa, kami tidak berhenti untuk terus berusaha menjadi lebih baik. Inilah sebabnya kami mengembangkan program-program untuk membantu orang-orang kami terus tumbuh dan menjadi lebih baik daripada saat pertama kali mereka bergabung.",
          OurProgram: "Program Kami",
          Training: "Pelatihan & Pengembangan",
          Webelieveourpeople: "Kami percaya bahwa orang-orang kami adalah berlian yang akan bersinar terang jika diasah dengan baik. Kami ingin melihat mereka menjadi pribadi yang lebih baik daripada saat mereka bergabung. Kami ingin menjadi bagian dari perjalanan mereka.",
          TeamBuilding: "Pembinaan Tim",
          Eachyear: "Setiap tahun, tim kami berkumpul untuk merapatkan barisan dan mempererat ikatan. Program ini dikembangkan untuk memperkuat tim kami dan meningkatkan kolaborasi kami.",
          CareerPlan: "Rencana Karir & Pelatihan Kepemimpinan",
          Webelievethateveryoneisaleader: "Kami percaya bahwa setiap orang adalah seorang pemimpin. Itulah mengapa rencana karirmu penting bagi kami. Kami ingin memastikan bahwa Anda akan menjadi bagian dari masa depan kami sejak Hari Pertama.",
          Activities: "Kegiatan",
          WorkingatOgya: "Bekerja di Ogya telah menjadi salah satu kebahagiaan terbesar saya. Sejak Hari Pertama, saya diberikan kesempatan untuk menjelajahi, belajar, dan bersinar dalam pekerjaan saya. Tempat ini kini semakin terasa seperti rumah. Saya sangat suka bekerja di sini!",
          JobOpportunities: "Kesempatan Kerja",
          JobVacancy: "Lowongan Pekerjaan",
          Functions: "Fungsi-fungsi",
          YourQuestions: "Hubungi Kami dan Dapatkan Jawaban untuk Pertanyaan Anda!",
          Whyhesitate: "Mengapa ragu ketika solusinya hanya sebatas sebuah formulir?",
          Werehere: "Kami hadir memberikan jawaban dan solusi yang Anda butuhkan. Tim ahli teknologi kami dengan antusias menunggu untuk membantu Anda dalam perjalanan IT Anda. Baik itu tentang mengoptimalkan jaringan, meningkatkan keamanan siber, atau memaksimalkan infrastruktur digital Anda, semuanya ada dalam cakupan kami.",
          Fillout: "Isi formulir berikut ini untuk berhubungan dengan kami. Kami mengerti bahwa menjelajahi dunia teknologi bisa jadi rumit, itulah mengapa kami berkomitmen dalam menyederhanakannya untuk Anda. Tidak ada pertanyaan yang terlalu panjang atau terlalu pendek-kami berdedikasi untuk menjawab semua kekhawatiran Anda.",
          Name: "Nama Lengkap",
          PhoneNumber: "No. Telepon",
          Email: "Email",
          CompanyName: "Nama Perusahaan",
          TopicInquiry: "Topik pencarian",
          NoticePeriod: "Periode Pemberitahuan",
          Whatdo: "Apa yang ingin Anda ketahui lebih lanjut?",
          ApplicationDevelopment: "Pengembangan Aplikasi",
          DataSecurity: "Keamanan Data dan Aplikasi",
          EnterpriseIntegration: "Integrasi Enterprise",
          Maintenance: "Pemeliharaan dan Dukungan Lokal",
          Implementation: "Implementasi dan Pengiriman Produk",
          ManagedService: "Layanan Kelola",
          OurGuidingCompass: "Kompas Pemandu Kami",
          OurDevelopmentJourney: "Perjalanan Pengembangan Kami",
          SoftwareDevelopment: "Karya Kami dalam ",
          more: "Selebihnya",
          OurTechnologyExpertise: "Keahlian Teknologi Kami",
          ApplyNow: "Lamar Sekarang",
          PersonalDetails: "Personal Details",
          UploadCV: "Unggah CV",
          CoverLetter: "Surat Lamaran",
          Submit: "Kirim",
          Requiredfield: "Wajib diisi",
          Department: "Bagian",
          Location: "Lokasi",
          DatePosted: "Tanggal Diposting",
          OurWork: "Karya Kami",
          Interested: "Tertarik untuk menciptakan sesuatu yang sama menariknya? Mari berbincang!",
          Messages: "Pesan",
          send: "Kirim",
          OurWorkswith: "Karya Kami dengan",
          TalkwithourEngineer: "Bicara dengan teknisi kami",
          Location: "Lokasi",
          lantai: "Lt. 3, Unit A",
          PTOgyaTeknoNusantarastands: "PT Ogya Tekno Nusantara berdiri sebagai perusahaan Teknologi Informasi dan Komunikasi (TIK) yang berpionir di Indonesia. OGYA berdedikasi untuk menyelaraskan elemen bisnis, manusia, dan teknologi secara mulus.",
          AtOGYAparamount: "Kepentingan utama bagi OGYA adalah menjamin kepuasan pelanggan melalui solusi bisnis dan teknologi yang OGYA tawarkan, menjadikan OGYA sebagai perusahaan solusi TI terpercaya dan handal di Indonesia.",
          WhatWeDo: "Apa yang kami lakukan ?",
          Testimony: "Kesaksian",
          TechnicalSolution: "Solusi Teknis",
        },
      },
    },
  });

export default i18n;